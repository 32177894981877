import Link from 'next/link'

import { withAssetPrefix } from '@helpers/assets'
import { getCountryOptions } from '@helpers/countries'
import FeatureToggle from '@helpers/feature_toggle'
import { getBeansIdUrl, getNextRoute } from '@helpers/routes'
import { TCountryCode } from '@typings/countries'
import { TTranslateFunction } from '@typings/translations'

import {
  COPYRIGHT_TM,
  EN_COUNTRIES,
  HOW_IT_WORKS_FALLBACK,
  GRAD_TITLE,
  ADVERTISE_FALLBACK,
  CAREERS_FALLBACK,
  ABOUT_FALLBACK,
  PRIVACY_POLICY_FALLBACK,
  TERMS_AND_CONDITIONS_FALLBACK,
  STUDENT_CARD_FALLBACK,
  ALL_CATEGORIES_FALLBACK,
  ALL_BRANDS_FALLBACK,
  ALL_COLLECTIONS_FALLBACK,
  SUPPORT_FAQS_FALLBACK,
  STUDENT_VERIFICATION_FALLBACK,
  STUDENT_MARKETING_FALLBACK,
  STUDENT_RESEARCH_FALLBACK,
  CHROME_EXTENSION_FALLBACK,
  ALL_CAMPAIGNS_FALLBACK,
  BEANSID_TITLE
} from './constants'
import { TFooterLink, TFooterLinkObject, TFooterLinks } from './types'

export const shouldRenderLink = (
  value: TFooterLink,
  country: TCountryCode,
  isCCGGraduate: boolean
): boolean => {
  if (isCCGGraduate && !value.graduate) return false

  const { hasGraduate, hasBeansId } = getCountryOptions(country)

  if (value.name === GRAD_TITLE) return hasGraduate

  if (value.name === BEANSID_TITLE) return hasBeansId

  return true
}

export const getFooterLinks = (
  t: TTranslateFunction,
  country: TCountryCode
): TFooterLinks => ({
  home: {
    student: {
      url: getNextRoute('home', { country }),
      svg: withAssetPrefix('images/sb_light_logo.svg'),
      alt: 'Student Beans logo'
    },
    graduate: {
      url: getNextRoute('grad.home', { country }),
      svg: withAssetPrefix('images/gradbeans/light_logo.svg'),
      alt: 'Grad Beans logo'
    }
  },
  socials: {
    instagram: { url: 'https://instagram.com/studentbeans' },
    tiktok: { url: 'https://www.tiktok.com/@studentbeans' },
    snapchat: { url: 'https://www.snapchat.com/add/studentbeansuk' },
    youtube: {
      url: 'https://www.youtube.com/channel/UCOzbkB9fkAq-33KLiRpQlyQ'
    }
  },
  workWithUs: {
    ...getImpressum(country),
    advertise: {
      name: t('a_advertise', { fallback: ADVERTISE_FALLBACK }),
      url: getNextRoute('footer.advertise', { country }),
      graduate: false
    },
    careers: {
      name: t('a_careers', { fallback: CAREERS_FALLBACK }),
      url: getNextRoute('footer.careers', { country }),
      graduate: true
    },
    ...getMarketing(t, country)
  },
  usefulStuff: {
    about: {
      name: t('a_about', { fallback: ABOUT_FALLBACK }),
      url: getNextRoute('footer.about', { country }),
      graduate: false,
      useLink: true
    },
    howItWorks: {
      name: t('a_footer_how_it_works', { fallback: HOW_IT_WORKS_FALLBACK }),
      url: getNextRoute('footer.how_it_works', { country }),
      graduate: false,
      useLink: true
    },
    privacy: {
      name: t('a_privacy_policy', { fallback: PRIVACY_POLICY_FALLBACK }),
      url: getNextRoute('footer.privacy', { country }),
      graduate: true
    },
    terms: {
      name: t('a_terms_conditions', {
        fallback: TERMS_AND_CONDITIONS_FALLBACK
      }),
      url: getNextRoute('footer.terms', { country }),
      graduate: true
    },
    student_card: {
      name: t('a_student_card', { fallback: STUDENT_CARD_FALLBACK }),
      url: getNextRoute('id.show', { country })
    },
    ...getChromeExtension(t, country),
    categories: {
      name: t('a_all_categories', { fallback: ALL_CATEGORIES_FALLBACK }),
      url: getNextRoute('footer.categories', { country }),
      graduate: false
    },
    brands: {
      name: t('a_all_brands', { fallback: ALL_BRANDS_FALLBACK }),
      url: getNextRoute('footer.brands', { country }),
      graduate: false
    },
    ...getAllCollections(t, country),
    ...getAllCampaigns(t, country),
    ...getGraduateDiscounts(country),
    ...getBeansIdDiscounts(country)
  },
  help: {
    faqs: {
      name: t('a_support_faqs', { fallback: SUPPORT_FAQS_FALLBACK }),
      url: getNextRoute('footer.faqs', {
        locale: getCountryOptions(country).zendesk
      })
    }
  }
})

export const getImpressum = (
  country: TCountryCode
): TFooterLinkObject | false => {
  return (
    country === 'de' && {
      impressum: {
        name: 'Impressum',
        url: getNextRoute('impressum', { country })
      }
    }
  )
}

export const getMarketing = (
  t: TTranslateFunction,
  country: TCountryCode
): TFooterLinkObject | false => {
  return (
    EN_COUNTRIES.includes(country) && {
      verification: {
        name: t('a_student_verification', {
          fallback: STUDENT_VERIFICATION_FALLBACK
        }),
        url: getNextRoute('footer.verification'),
        graduate: true
      },
      marketing: {
        name: t('a_student_marketing', {
          fallback: STUDENT_MARKETING_FALLBACK
        }),
        url: getNextRoute('footer.marketing'),
        graduate: true
      },
      research: {
        name: t('a_student_research', { fallback: STUDENT_RESEARCH_FALLBACK }),
        url: getNextRoute('footer.research'),
        graduate: true
      }
    }
  )
}

export const getChromeExtension = (
  t: TTranslateFunction,
  country: TCountryCode
): TFooterLinkObject | false => {
  return (
    EN_COUNTRIES.includes(country) && {
      chrome_extension: {
        name: t('a_chrome_extension', { fallback: CHROME_EXTENSION_FALLBACK }),
        url: getNextRoute('footer.chrome_extension')
      }
    }
  )
}

export const getCopyrightText = (): string => {
  const copyrightSymbol = String.fromCharCode(COPYRIGHT_TM)
  const currentYear = new Date().getFullYear()

  return `${copyrightSymbol} ${currentYear} Student Beans`
}

export const getGraduateDiscounts = (
  country: TCountryCode
): TFooterLinkObject => {
  const url = getNextRoute('grad.home', { country })
  return {
    graduate: {
      name: GRAD_TITLE,
      url,
      graduate: true
    }
  }
}

const getBeansIdDiscounts = (country: TCountryCode): TFooterLinkObject => {
  const url = getBeansIdUrl(country)
  return {
    beansid: {
      name: BEANSID_TITLE,
      url,
      graduate: true
    }
  }
}

const getAllCollections = (
  t: TTranslateFunction,
  country: TCountryCode
): TFooterLinkObject | null => {
  if (!FeatureToggle.isEnabled('collections_page')) return null

  return {
    collections: {
      name: t('a_all_collections', { fallback: ALL_COLLECTIONS_FALLBACK }),
      url: getNextRoute('footer.collections', { country }),
      graduate: false
    }
  }
}

const getAllCampaigns = (
  t: TTranslateFunction,
  country: TCountryCode
): TFooterLinkObject | null => {
  if (!FeatureToggle.isEnabled('campaigns_page')) return null

  return {
    campaigns: {
      name: t('a_all_campaigns', { fallback: ALL_CAMPAIGNS_FALLBACK }),
      url: getNextRoute('footer.campaigns', { country }),
      graduate: false
    }
  }
}

export const getLinkComponent = (value: TFooterLink): typeof Link | string => {
  if (value.useLink) return Link

  return 'a'
}
