import { ANDROID } from '@constants/device_type'
import { withAssetPrefix } from '@helpers/assets'
import { isCountryUK, isCountryUS } from '@helpers/countries'
import FeatureToggle from '@helpers/feature_toggle'
import { TCountryCode } from '@typings/countries'

import {
  LANG_KEYS,
  TRANSLATION_KEYS,
  IMG_FORMATS,
  APPS_DL_IMAGE_SIZE_WIDTH_MAP,
  FOOTER,
  NEW_APPS_PAGE_TITLE,
  APP_DOWNLOAD_BANNER,
  US_HREF,
  UK_HREF,
  DEFAULT_ANDROID_HREF,
  DEFAULT_IOS_HREF,
  GB
} from './constants'
import { TAppLinkPlatform, TAppLinkSize } from './types'

const getHref = (
  locationOnPage: string,
  country: TCountryCode,
  isAndroid: boolean
): string => {
  if (FeatureToggle.isEnabled('apps_flier'))
    return getLocationSpecificHref(locationOnPage, country, isAndroid)

  return getDefaultHref(country, isAndroid)
}

const getCountryCode = (country: TCountryCode): TCountryCode | typeof GB => {
  if (isCountryUK(country)) return GB

  return country.toLowerCase() as TCountryCode
}

export const getAppLinkData = (
  country: TCountryCode,
  platform: TAppLinkPlatform,
  locationOnPage: string
): {
  href: string
  src: string
  translationKey: string
  translationFallback: string
} => {
  const isAndroid = platform === ANDROID

  const href = getHref(locationOnPage, country, isAndroid)
  const src = getSrc(country, platform, isAndroid)
  const translationKey = getTranslationKey(isAndroid)
  const translationFallback = getTranslationFallback(isAndroid)

  return { href, src, translationKey, translationFallback }
}

export const getDefaultHref = (
  country: TCountryCode,
  isAndroid: boolean
): string => {
  if (isAndroid) return DEFAULT_ANDROID_HREF(getCountryCode(country))

  return DEFAULT_IOS_HREF(getCountryCode(country))
}

export const getLocationSpecificHref = (
  locationOnPage: string,
  country: TCountryCode,
  isAndroid: boolean
): string => {
  if (locationOnPage === FOOTER) return getAppsFooterHref(country, isAndroid)

  if (locationOnPage === NEW_APPS_PAGE_TITLE)
    return getAppsPageHref(country, isAndroid)

  if (locationOnPage === APP_DOWNLOAD_BANNER)
    return getAppsBannerWidgetHref(country, isAndroid)

  return getDefaultHref(country, isAndroid)
}

export const getAppsFooterHref = (
  country: TCountryCode,
  isAndroid: boolean
): string => {
  if (isCountryUS(country)) {
    if (isAndroid) return US_HREF.FOOTER_ANDROID

    return US_HREF.FOOTER_IOS
  }
  if (isCountryUK(country)) {
    if (isAndroid) return UK_HREF.FOOTER_ANDROID

    return UK_HREF.FOOTER_IOS
  }

  return getDefaultHref(country, isAndroid)
}

export const getAppsPageHref = (
  country: TCountryCode,
  isAndroid: boolean
): string => {
  if (isCountryUS(country)) {
    if (isAndroid) return US_HREF.APPS_PAGE_ANDROID

    return US_HREF.APPS_PAGE_IOS
  }
  if (isCountryUK(country)) {
    if (isAndroid) return UK_HREF.APPS_PAGE_ANDROID

    return UK_HREF.APPS_PAGE_IOS
  }

  return getDefaultHref(country, isAndroid)
}

export const getAppsBannerWidgetHref = (
  country: TCountryCode,
  isAndroid: boolean
): string => {
  if (isCountryUS(country)) {
    if (isAndroid) return US_HREF.APPS_WIDGET_ANDROID

    return US_HREF.APPS_WIDGET_IOS
  }
  if (isCountryUK(country)) {
    if (isAndroid) return UK_HREF.APPS_WIDGET_ANDROID

    return UK_HREF.APPS_WIDGET_IOS
  }

  return getDefaultHref(country, isAndroid)
}

export const getSrc = (
  country: TCountryCode,
  platform: TAppLinkPlatform,
  isAndroid: boolean
): string => {
  const format = isAndroid ? IMG_FORMATS.android : IMG_FORMATS.ios
  const lang = LANG_KEYS[country as keyof typeof LANG_KEYS] ?? LANG_KEYS.uk
  return withAssetPrefix(`images/apps/${platform}_${lang}.${format}`)
}

export const getTranslationKey = (isAndroid: boolean): string =>
  isAndroid ? TRANSLATION_KEYS.android : TRANSLATION_KEYS.ios

export const getTranslationFallback = (isAndroid: boolean): string =>
  isAndroid ? TRANSLATION_KEYS.androidFallback : TRANSLATION_KEYS.iosFallback

export const getHeightByAspectRatio = (width: number): number => width / 3

export const getImageSize = (
  width: TAppLinkSize
): { width: number; height: number } => ({
  width: APPS_DL_IMAGE_SIZE_WIDTH_MAP[width],
  height: getHeightByAspectRatio(APPS_DL_IMAGE_SIZE_WIDTH_MAP[width])
})
