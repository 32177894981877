'use client'

import clsx from 'clsx'

import InstagramIcon from '@thebeansgroup/ui/icons/instagram'
import SnapchatIcon from '@thebeansgroup/ui/icons/snapchat'
import TiktokIcon from '@thebeansgroup/ui/icons/tiktok'
import YoutubeIcon from '@thebeansgroup/ui/icons/youtube'
import Typography from '@thebeansgroup/ui/typography'

import AppDownloadBanner from '@components/app_download_banner'
import AppDownloadBlock from '@components/app_download_block'
import CountryLink from '@components/country_link'
import SafeImage from '@components/safe_image'
import { getCountryOptions } from '@helpers/countries'
import { useCCG } from '@hooks/use_ccg'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'
import { NullableReactElement } from '@typings/utils'

import {
  WHERE_TO_FIND_US_TITLE,
  WHERE_TO_FIND_US_TEXT,
  YOUTUBE_LABEL,
  TIKTOK_LABEL,
  INSTAGRAM_LABEL,
  SNAPCHAT_LABEL,
  COUNTRIES_SELECT_FALLBACK,
  FOOTER_WORK_WITH_US_FALLBACK,
  FOOTER_NEED_HELP_FALLBACK,
  FOOTER_USEFUL_STUFF_FALLBACK
} from './constants'
import {
  getFooterLinks,
  getCopyrightText,
  getLinkComponent,
  shouldRenderLink
} from './helpers'
import { TFooterLinkObject, TFooterProps } from './types'

const Footer = ({
  displayAppDownloadBanner
}: TFooterProps): React.ReactElement => {
  const { t } = useI18n()
  const { ccg, isGradBeansPage } = useCCG()
  const country = useCountryCode()
  const footerLinks = getFooterLinks(t, country)
  const { hasApp } = getCountryOptions(country)

  const renderHead = (): React.ReactElement => (
    <div className='flex flex-col my-0 mx-auto py-7 px-[30px] md:px-4 md:pb-[50px] md:items-center lg:px-[20px] lg:pb-[35px] lg:flex-row lg:justify-between '>
      <a href={footerLinks.home[ccg as string].url}>
        <SafeImage
          src={footerLinks.home[ccg as string].svg as string}
          alt={footerLinks.home[ccg as string].alt as string}
          width={163.5}
          height={28}
        />
      </a>
      {renderSocialIconsList()}
    </div>
  )

  const renderSocialIconsList = (): NullableReactElement => {
    if (isGradBeansPage) return null

    return <ul className='flex list-none m-0 p-0'>{renderSocialIcons()}</ul>
  }

  const renderSocialIcons = (): React.ReactElement[] => {
    return Object.entries(footerLinks.socials).map(([key, value]) => (
      <li
        key={`socials-${key}`}
        className='inline-block pl-2 leading-none'
      >
        <a href={value.url}>{renderSocialIcon(key)}</a>
      </li>
    ))
  }

  const renderSocialIcon = (key: string): React.ReactElement => {
    if (key === 'instagram')
      return (
        <InstagramIcon
          size={23}
          label={INSTAGRAM_LABEL}
          className='fill-white-300 hover:fill-neon-500 stroke-none'
        />
      )

    if (key === 'youtube')
      return (
        <YoutubeIcon
          size={18}
          label={YOUTUBE_LABEL}
          className='fill-white-300 hover:fill-neon-500 pt-2 stroke-none'
        />
      )

    if (key === 'tiktok')
      return (
        <TiktokIcon
          size={23}
          label={TIKTOK_LABEL}
          className='fill-white-300 hover:fill-neon-500 stroke-none'
        />
      )

    return (
      <SnapchatIcon
        size={23}
        label={SNAPCHAT_LABEL}
        className='fill-white-300 hover:fill-neon-500'
      />
    )
  }

  const renderResourcesRow = (
    text: string,
    list: TFooterLinkObject
  ): React.ReactElement => (
    <div>
      <Typography
        variant='headline'
        component='h2'
        fontWeight='semibold'
        colour='white-300'
      >
        {text}
      </Typography>
      <ul className='list-none m-0 p-0'>{renderListItems(list)}</ul>
    </div>
  )

  const renderWhereToFindUs = (): NullableReactElement => {
    if (isGradBeansPage) return null

    return (
      <div className='whitespace-pre-line lg:grid-cols-subgrid lg:col-span-3'>
        <Typography
          variant='headline'
          component='h2'
          fontWeight='semibold'
          colour='white-300'
        >
          {t('d_footer_find_us', { fallback: WHERE_TO_FIND_US_TITLE })}
        </Typography>
        <Typography
          variant='subhead'
          colour='liquorice-200'
        >
          {t('m_footer_find_us', { fallback: WHERE_TO_FIND_US_TEXT })}
        </Typography>
      </div>
    )
  }

  const renderListItems = (list: TFooterLinkObject): NullableReactElement[] => {
    return Object.entries(list).map(([key, value]) => {
      if (!shouldRenderLink(value, country, isGradBeansPage)) return null

      const Element = getLinkComponent(value)

      return (
        <li key={`socials-${key}`}>
          <Element href={value.url as string}>
            <Typography
              variant='subhead'
              colour='liquorice-200'
            >
              {value.name}
            </Typography>
          </Element>
        </li>
      )
    })
  }

  const renderAppLinks = (): NullableReactElement => {
    if (isGradBeansPage || !hasApp) return null

    return (
      <div className='flex order-last lg:block lg:grid-cols-subgrid lg:col-span-2 lg:order-none'>
        <div className='flex-shrink-0 xl:mt-0 pb-3'>
          <div className='flex p-4 bg-violet-400 rounded-4 justify-center items-center max-w-fit'>
            <AppDownloadBlock
              location='footer'
              size='medium'
              background='dark'
            />
          </div>
        </div>
      </div>
    )
  }

  const renderFootnoteContent = (): React.ReactElement => (
    <div className='flex flex-col-reverse items-center md:flex-row md:justify-between'>
      <Typography
        variant='caption1'
        colour='liquorice-200'
      >
        {getCopyrightText()}
      </Typography>
      <div className='flex items-center my-[10px] mx-2'>
        <Typography
          variant='caption1'
          colour='liquorice-200'
          className='mr-3'
        >
          {t('d_select_country_region', {
            fallback: COUNTRIES_SELECT_FALLBACK
          })}
        </Typography>
        <div className='py-2 px-[10px] border-2 border-solid border-grey-350 rounded-2'>
          <CountryLink
            country={country}
            position='footer'
          />
        </div>
      </div>
    </div>
  )

  const renderAppDownloadBanner = (): NullableReactElement => {
    if (!displayAppDownloadBanner || !hasApp) return null

    return <AppDownloadBanner />
  }

  const renderHelpRow = (): NullableReactElement => {
    if (isGradBeansPage) return null

    return (
      <div>
        {renderResourcesRow(
          t('footer.need_help', {
            fallback: FOOTER_NEED_HELP_FALLBACK
          }),
          footerLinks.help
        )}
      </div>
    )
  }

  return (
    <footer
      className={clsx(
        'w-full text-left z-1',
        !isGradBeansPage && 'bg-violet-500',
        isGradBeansPage && 'bg-huckleberry-500'
      )}
    >
      {renderAppDownloadBanner()}
      <div className='max-w-[580px] my-0 mx-auto lg:max-w-[1250px] lg:w-[88.333%]'>
        {renderHead()}
        <div className='grid grid-flow-row gap-y-7 px-6 md:grid-cols-2 md:gap-x-2 lg:grid-cols-5 lg:px-5 lg:pb-4 lg:pt-0'>
          {renderResourcesRow(
            t('footer.work_with_us', {
              fallback: FOOTER_WORK_WITH_US_FALLBACK
            }),
            footerLinks.workWithUs
          )}
          {renderResourcesRow(
            t('footer.useful_stuff', {
              fallback: FOOTER_USEFUL_STUFF_FALLBACK
            }),
            footerLinks.usefulStuff
          )}
          {renderHelpRow()}
          {renderAppLinks()}
          {renderWhereToFindUs()}
        </div>
      </div>
      <div className='max-w-[1250px] my-0 mx-auto py-0 px-4'>
        <div className='h-1 border-[0.5px] border-solid border-liquorice-350 my-2 mx-auto' />
        {renderFootnoteContent()}
      </div>
    </footer>
  )
}

export default Footer
