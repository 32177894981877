import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'

const AppStarRating = (): React.ReactElement => {
  return (
    <div className='flex-row ml-1 leading-[1.2] text-pina-colada-300'>
      <SafeImage
        src={withAssetPrefix('images/app_rating_star.svg')}
        alt='star rating'
        height={11}
        width={11}
      />
    </div>
  )
}

export default AppStarRating
