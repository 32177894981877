import dynamic from 'next/dynamic'

import Button from '@thebeansgroup/ui/button'
import Typography from '@thebeansgroup/ui/typography'

import Grid from '@components/grid'
import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'
import { getNextRoute } from '@helpers/routes'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'

import {
  LEARN_MORE_FALLBACK,
  SUBTITLE_FALLBACK,
  TITLE_FALLBACK
} from './constants'

const AppLink = dynamic(() => import('@components/app_link'), { ssr: false })

const AppDownloadBanner = (): React.ReactElement => {
  const { t } = useI18n()
  const country = useCountryCode()

  const renderTitle = (): React.ReactElement => (
    <Typography
      variant='uber2'
      fontWeight='bold'
      className='text-neon-500 uppercase'
      component='h2'
    >
      {t('d_app_download_banner', { fallback: TITLE_FALLBACK })}
    </Typography>
  )

  const renderSubTitle = (): React.ReactElement => (
    <Typography
      variant='title2'
      className='text-white-300 max-w-md'
      component='h2'
    >
      {t('m_app_download_banner', { fallback: SUBTITLE_FALLBACK })}
    </Typography>
  )

  const renderImage = (): React.ReactElement => {
    return (
      <div className='m-auto lg:m-0 xl:min-w-fit'>
        <SafeImage
          alt=''
          src={withAssetPrefix('images/app_download_banner_app.png')}
          width={607}
          height={480}
          style={{ objectFit: 'contain', maxWidth: '100%' }}
        />
      </div>
    )
  }

  const renderCtas = (): React.ReactElement => (
    <div className='flex flex-col justify-between items-center w-full gap-4 lg:flex-row lg:items-stretch lg:max-w-md'>
      <Button
        display='inverse'
        size='condensed'
        component='a'
        href={getNextRoute('apps', { country })}
      >
        {t('a_learn_more', { fallback: LEARN_MORE_FALLBACK })}
      </Button>
      <div className='flex flex-row'>
        <AppLink
          platform='ios'
          locationOnPage='app_download_banner'
          size='medium'
        />
        <AppLink
          platform='android'
          locationOnPage='app_download_banner'
          size='medium'
        />
      </div>
    </div>
  )

  const renderContent = (): React.ReactElement => (
    <div className='flex flex-col justify-evenly items-center text-center py-4 min-h-[400px] lg:text-left lg:items-start lg:max-w-[491px] lg:py-[30px] xl:min-w-max'>
      {renderTitle()}
      {renderSubTitle()}
      {renderCtas()}
    </div>
  )

  return (
    <div className='gradient-violet-fall'>
      <Grid className='mx-auto max-w-[1025px] px-3'>
        <div className='flex flex-col justify-between gap-0 lg:flex-row lg:gap-[50px]'>
          {renderContent()}
          {renderImage()}
        </div>
      </Grid>
    </div>
  )
}

export default AppDownloadBanner
