import { TAppLinkSize } from '@components/app_link/types'

import { TAppDownloadBlockBackground } from './types'

export const getAverageColour = (
  background: TAppDownloadBlockBackground
): string => {
  if (background === 'light') return 'text-grey-500'

  return 'text-white-300'
}

export const getRatingsColour = (
  background: TAppDownloadBlockBackground
): string => {
  if (background === 'light') return 'text-grey-400'

  return 'text-white-300'
}

export const getRatingsSize = (size: TAppLinkSize): string => {
  if (size === 'small') return 'text-[12px]'

  return 'text-[15px]'
}

export const getAverageSize = (size: TAppLinkSize): string => {
  if (size === 'small') return 'text-[15px]'

  if (size === 'large') return 'text-[21px]'

  return 'text-[18px]'
}
