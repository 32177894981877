export const COPYRIGHT_TM = 169

export const EN_COUNTRIES = ['uk', 'us', 'au', 'ie', 'nz', 'ca']

export const WHERE_TO_FIND_US_TEXT =
  'Student Beans is operated by The Beans Group. \n Registered in England and Wales under company number 5486885. \n Registered office 1 Vincent Square, London, SW1 2PN.'

export const WHERE_TO_FIND_US_TITLE = 'Where to find us'

export const GRAD_TITLE = 'Graduate Discounts'

export const INSTAGRAM_LABEL = 'Instagram'
export const YOUTUBE_LABEL = 'Youtube'
export const TIKTOK_LABEL = 'Tiktok'
export const SNAPCHAT_LABEL = 'Snapchat'

export const ADVERTISE_FALLBACK = 'Advertise'
export const CAREERS_FALLBACK = 'Careers'
export const ABOUT_FALLBACK = 'About'
export const HOW_IT_WORKS_FALLBACK = 'How it works'
export const PRIVACY_POLICY_FALLBACK = 'Privacy Policy'
export const TERMS_AND_CONDITIONS_FALLBACK = 'Terms & Conditions'
export const STUDENT_CARD_FALLBACK = 'Student Card'
export const ALL_CATEGORIES_FALLBACK = 'All Categories'
export const ALL_BRANDS_FALLBACK = 'All Brands'
export const ALL_COLLECTIONS_FALLBACK = 'All Collections'
export const ALL_CAMPAIGNS_FALLBACK = 'All Campaigns'
export const SUPPORT_FAQS_FALLBACK = 'Support FAQs'
export const STUDENT_VERIFICATION_FALLBACK = 'Student Verification'
export const STUDENT_MARKETING_FALLBACK = 'Student Marketing'
export const STUDENT_RESEARCH_FALLBACK = 'Student Research'
export const CHROME_EXTENSION_FALLBACK = 'Chrome Extension'
export const COUNTRIES_SELECT_FALLBACK = 'Select your country / region'
export const FOOTER_WORK_WITH_US_FALLBACK = 'Work With Us'
export const FOOTER_USEFUL_STUFF_FALLBACK = 'Useful Stuff'
export const FOOTER_NEED_HELP_FALLBACK = 'Need Help?'

export const BEANSID_TITLE = 'BeansiD Discounts'
