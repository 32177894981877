import clsx from 'clsx'

import AppLink from '@components/app_link'
import AppStarRating from '@components/app_star_rating'

import {
  ANDROID_AVERAGE_RATING,
  ANDROID_NUMERICAL_RATING,
  IOS_AVERAGE_RATING,
  IOS_NUMERICAL_RATING
} from './constants'
import {
  getAverageColour,
  getAverageSize,
  getRatingsColour,
  getRatingsSize
} from './helpers'
import { TAppDownloadBlockProps } from './types'

const AppDownloadBlock = ({
  background = 'light',
  size,
  location
}: TAppDownloadBlockProps): React.ReactElement => {
  const averageColour = getAverageColour(background)
  const ratingsColour = getRatingsColour(background)

  const averageSize = getAverageSize(size)
  const ratingsSize = getRatingsSize(size)

  return (
    <div
      className='flex flex-col font-gibson w-max gap-3'
      data-testid='app-promo-box'
    >
      <div className='flex gap-2 items-start'>
        <AppLink
          platform='ios'
          size={size}
          locationOnPage={location}
        />
        <div>
          <div className='flex items-center leading-[1]'>
            <div
              className={clsx(averageColour, averageSize, 'font-semibold mr-2')}
            >
              {IOS_AVERAGE_RATING}
            </div>
            <AppStarRating />
            <AppStarRating />
            <AppStarRating />
            <AppStarRating />
            <AppStarRating />
          </div>
          <div className={clsx(ratingsColour, ratingsSize, 'leading-[1.3]')}>
            {IOS_NUMERICAL_RATING}
          </div>
        </div>
      </div>

      <div className='flex gap-2 items-start'>
        <AppLink
          platform='android'
          size={size}
          locationOnPage={location}
        />
        <div>
          <div className='flex items-center leading-[1]'>
            <div
              className={clsx(averageColour, averageSize, 'font-semibold mr-2')}
            >
              {ANDROID_AVERAGE_RATING}
            </div>
            <AppStarRating />
            <AppStarRating />
            <AppStarRating />
            <AppStarRating />
            <AppStarRating />
          </div>
          <div className={clsx(ratingsColour, ratingsSize, 'leading-[1.3]')}>
            {ANDROID_NUMERICAL_RATING}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppDownloadBlock
