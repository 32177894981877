'use client'

import SafeImage from '@components/safe_image'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'
import { trackUnstructEvent } from '@tracker/snowplow'

import { APP_DOWNLOAD_LINK_SCHEMA } from './constants'
import { getAppLinkData, getImageSize } from './helpers'
import { TAppLinkProps } from './types'

const AppLink = ({
  platform,
  size,
  locationOnPage,
  onClick = (): void => {}
}: TAppLinkProps): React.ReactElement => {
  const country = useCountryCode()
  const { t } = useI18n()
  const { href, src, translationKey, translationFallback } = getAppLinkData(
    country,
    platform,
    locationOnPage
  )
  const { height, width } = getImageSize(size)

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault()

    trackUnstructEvent(APP_DOWNLOAD_LINK_SCHEMA, {
      app_store: platform,
      country_code: country,
      location_on_page: locationOnPage
    })

    onClick(event)

    location.assign(href)
  }

  return (
    <a
      className='inline-block w-fit h-[40px] mr-3'
      href={href}
      onClick={handleClick}
    >
      <SafeImage
        className='block'
        src={src}
        alt={t(translationKey, { fallback: translationFallback })}
        height={height}
        width={width}
      />
    </a>
  )
}

export default AppLink
