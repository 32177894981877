import { TCountryCode } from '@typings/countries'

export const LANG_KEYS = {
  uk: 'en',
  us: 'en',
  ca: 'en',
  au: 'en',
  de: 'de',
  fr: 'fr'
}

export const TRANSLATION_KEYS = {
  android: 'app_download_links.android',
  ios: 'app_download_links.ios',
  androidFallback: 'Get it on Google Play',
  iosFallback: 'Download on the App Store'
}

export const IMG_FORMATS = {
  android: 'png',
  ios: 'svg'
}

export const APPS_DL_IMAGE_SIZE_WIDTH_MAP = {
  small: 100,
  medium: 135,
  large: 200
}

export const APP_DOWNLOAD_LINK_SCHEMA =
  'iglu:com.studentbeans/app_download_link_click/jsonschema/1-0-0'

export const FOOTER = 'footer'
export const NEW_APPS_PAGE_TITLE = 'new_apps_page_title'
export const APP_DOWNLOAD_BANNER = 'app_download_banner'

export const US_HREF = {
  FOOTER_ANDROID: 'https://studentbeans.onelink.me/9poK/13p64ixl',
  FOOTER_IOS: 'https://studentbeans.onelink.me/9poK/2zh46h5h',
  APPS_PAGE_ANDROID: 'https://studentbeans.onelink.me/9poK/jx74mcvh',
  APPS_PAGE_IOS: 'https://studentbeans.onelink.me/9poK/79anvu3i',
  APPS_WIDGET_ANDROID: 'https://studentbeans.onelink.me/9poK/fdxnm970',
  APPS_WIDGET_IOS: 'https://studentbeans.onelink.me/9poK/wye3lx1n'
}

export const UK_HREF = {
  FOOTER_ANDROID: 'https://studentbeans.onelink.me/9poK/cindu6pm',
  FOOTER_IOS: 'https://studentbeans.onelink.me/9poK/hh78r08f',
  APPS_PAGE_ANDROID: 'https://studentbeans.onelink.me/9poK/felsp60t',
  APPS_PAGE_IOS: 'https://studentbeans.onelink.me/9poK/fn8tgr2b',
  APPS_WIDGET_ANDROID: 'https://studentbeans.onelink.me/9poK/kxvu9lf1',
  APPS_WIDGET_IOS: 'https://studentbeans.onelink.me/9poK/ne8388ko'
}

export const DEFAULT_ANDROID_HREF = (
  countryCode: TCountryCode | typeof GB
): string =>
  `https://play.google.com/store/apps/details?id=com.studentbeans.studentbeans&hl=${countryCode}&referrer=utm_source%3Dstudentbeans%26utm_medium%3Dapp_page`

export const DEFAULT_IOS_HREF = (
  countryCode: TCountryCode | typeof GB
): string =>
  `https://itunes.apple.com/${countryCode}/app/apple-store/id994482161?pt=10031819&ct=studentbeans-app_page&mt=8`

export const GB = 'gb'
